.footer_wrapper {
  width: 100%;
  background-color: #403e44;
  /*background-image: url(D:\Dokumenty\Cegys_web\src\assets\images\medical_icons.png);*/
}

.footer {
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 25px;
  display: block;
  box-sizing: border-box;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: deeppink;
}

.container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.add {
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding: 2% 0 2% 0;
  min-height: 150px;
}

.banner {
  width: 45%;
  position: relative;
  background-color: #403e44;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  display: inline-block;
  border: 5px solid #524f56;
  border-radius: 5px;
  margin: 2.5% 2.5% 0 2.5%;
  text-align: center;
}

.inline {
  margin: 40px 0 0 0;
  padding: 0 25px 25px 25px;
  border: 7px solid #524f56;
  color: #cccccc;
  position: relative;
  transition: all 0.15s linear;
  height: 135px;
  width: 360px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #403e44;
}

.inline img {
  margin-top: -40px;
  background: #403e44;
  font-size: 36px;
  position: relative;
  height: 32px;
  width: 32px;
  line-height: 37px;
  display: inline-block;
  text-align: center;
  transition: all 0.15 linear;
  box-sizing: border-box;
}

.icon img {
  font-family: "Stroke-Gap-Icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

.footer_text {
  color: #cccccc;
  font-size: 14px;
  height: auto;
  font-weight: 300;
  text-align: center;
  float: none;
  max-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 1px;
  position: relative;
  margin-top: 15px;
}

.footer span {
  display: block;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
}

.footer_text {
  text-align: center;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1130px) {
  .container {
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .inline {
    width: 320px;
  }
}

@media only screen and (max-width: 320px) {
  .inline {
    width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .hidden {
    display: none;
  }
}
