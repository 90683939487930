.headLine {
  margin-top: 25px;
  color: deeppink;
  font-weight: bold;
}

@media only screen and (max-width: 1320px) {
  .headLine {
    margin-left: 3%;
  }
}
