.wrapper {
  background-color: white;
  min-width: 280px;
  height: auto;
  margin: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; 
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.5);
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  
}

.wrapper div h1{
  color: white;
}

.description h3{
  color: white;
}

.certificates h3{
  color: deeppink;
}

.photo img {
  margin: 5px;
  border-radius: 10px;
  background-color: antiquewhite;
  min-width: 96px;
  min-height: 96px;
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.3) ;
  
  /*float: left;*/
}

.description {
  background-color: #403e44;
  margin: 5px;
  border-radius: 10px;
  width: 380px;
  color: white;
  padding: 10px;
}


.description span {
  display: block;
  margin-bottom: 0.5em;
}

.certificates {
  width: 590px;
  background-color: #403e44;
  float: left;
  margin: 5px;
  border-radius: 10px;
  color: white;
  padding: 10px;
}

.certificates span {
  display: block;
}


@media only screen and (max-width: 649px) {
  .description {
    width: 100%;
  }
}